// extracted by mini-css-extract-plugin
export var alignDiscLeft = "w_r1 d_fp d_bG d_dv";
export var alignLeft = "w_qR d_fp d_bG d_dv";
export var alignDiscCenter = "w_r2 d_fq d_bD d_dw";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignDiscRight = "w_r3 d_fr d_bH d_dx";
export var alignRight = "w_qS d_fr d_bH d_dx";
export var footerContainer = "w_r4 d_dW d_bW";
export var footerContainerFull = "w_r5 d_dT d_bW";
export var footerHeader = "w_kf d_kf";
export var footerTextWrapper = "w_r6 d_w";
export var footerDisclaimerWrapper = "w_km d_km d_ck";
export var badgeWrapper = "w_r7 d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "w_r8 d_bz d_bJ d_bN d_bL";
export var wide = "w_r9 d_cy";
export var right = "w_sb d_bK";
export var line = "w_fk d_fl d_cv";
export var badgeDisclaimer = "w_sc d_bC d_bP d_bJ";
export var badgeContainer = "w_sd d_bz d_bH d_bP";
export var badge = "w_sf";
export var padding = "w_sg d_c7";
export var end = "w_sh d_bH";
export var linkWrapper = "w_sj d_dB";
export var link = "w_mC d_dB";
export var colWrapper = "w_sk d_cx";
export var consent = "w_f d_f d_bC d_bP";
export var disclaimer = "w_sl d_bz d_bJ";
export var media = "w_sm d_bx d_dy";
export var itemRight = "w_sn";
export var itemLeft = "w_sp";
export var itemCenter = "w_sq";
export var exceptionWeight = "w_qY C_tq";