// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_sr d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_ss d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_st d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_sv d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_sw d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_sx d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_sy d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_sz C_sz";
export var heroExceptionNormal = "x_sB C_sB";
export var heroExceptionLarge = "x_sC C_sC";
export var Title1Small = "x_sD C_sD C_sZ C_s0";
export var Title1Normal = "x_sF C_sF C_sZ C_s1";
export var Title1Large = "x_sG C_sG C_sZ C_s2";
export var BodySmall = "x_rG C_rG C_sZ C_th";
export var BodyNormal = "x_rH C_rH C_sZ C_tj";
export var BodyLarge = "x_rJ C_rJ C_sZ C_tk";