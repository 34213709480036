// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "y_sr d_gv d_cs";
export var heroHeaderCenter = "y_gw d_gw d_cs d_dw";
export var heroHeaderRight = "y_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "y_ss d_gr d_cw";
export var heroParagraphCenter = "y_gs d_gs d_cw d_dw";
export var heroParagraphRight = "y_gt d_gt d_cw d_dx";
export var heroSliderBtnWrapperLeft = "y_sH d_d2 d_d1 d_w d_bz d_bG d_bJ";
export var heroSliderBtnWrapperCenter = "y_sJ d_d3 d_d1 d_w d_bz d_bD d_bJ";
export var heroSliderBtnWrapperRight = "y_sK d_d4 d_d1 d_w d_bz d_bH d_bJ";
export var btnWrapper = "y_d2";
export var wrapper = "y_sL d_bz d_bP d_w d_H";
export var background = "y_ng d_bd d_0 d_8 d_7 d_4 d_9";
export var frameWrapper = "y_sM d_w d_H d_Z d_4 d_7 d_bk d_bf d_bv";
export var videoFrame = "y_sN d_0";
export var videoOverlay = "y_sP d_w d_H d_0 d_4";
export var arrow = "y_kG d_0 d_n";
export var arrowLeft = "y_sQ y_kG d_0 d_n";
export var arrowRight = "y_sR y_kG d_0 d_n";
export var buttonsWrapper = "y_sS d_bz d_bP";
export var dotsWrapper = "y_sT d_bz d_bP d_bD d_0";
export var dot = "y_sV d_bx d_dB";
export var dotActive = "y_sW y_sV d_bx d_dB";
export var heroSliderBackgroundImage = "y_gz d_w d_H d_bR d_bk";
export var loadingWrapper = "y_sX d_bd d_0 d_8 d_7 d_4 d_9";
export var topAlt = "y_gC d_gC d_gB d_bz d_bP d_w";
export var top = "y_gD d_gD d_gB d_bz d_bP d_w";
export var bottomAlt = "y_gG d_gG d_bd d_0 d_8 d_7 d_4 d_9 d_gB d_bz d_bP";
export var bottom = "y_gH d_gH d_bd d_0 d_8 d_7 d_4 d_9 d_gB d_bz d_bP";
export var heroExceptionSmall = "y_sz C_sz";
export var heroExceptionNormal = "y_sB C_sB";
export var heroExceptionLarge = "y_sC C_sC";