// extracted by mini-css-extract-plugin
export var alignLeft = "t_qR d_fp d_bG d_dv";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignRight = "t_qS d_fr d_bH d_dx";
export var FAQMainHeader = "t_kt d_kt d_w d_by";
export var FAQComponentHeader = "t_kz d_kz d_bx";
export var FAQComponentParagraph = "t_kv d_kv d_cD";
export var FAQComponentsWrapper = "t_kr d_kr d_cn d_cL d_cR d_c2 d_cX d_dB";
export var FAQComponentsWrapperClean = "t_ks d_ks d_cn d_cL d_cR d_c0 d_dB";
export var accordion__body = "t_kB d_kB d_by";
export var accordion__bodyHidden = "t_kD d_kD d_bw d_dl";
export var FAQDivider = "t_kq d_kq";
export var accordionWrapper = "t_rK d_cg";
export var accordion__item = "t_rL d_cL";
export var accordion_arrow = "t_kH d_kH";
export var accordion_arrow_open = "t_kK d_kK";
export var accordion_arrow_2 = "t_kM d_kM";
export var accordion_arrow_2_open = "t_kP d_kP";
export var Subtitle2Small = "t_rM C_rM C_sZ C_td";
export var Subtitle2Normal = "t_rN C_rN C_sZ C_tf";
export var Subtitle2Large = "t_rP C_rP C_sZ C_tg";