// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "r_qZ d_bD";
export var datasheetRowWrapper = "r_lt d_lt d_bK";
export var datasheetLeftWrapper = "r_q0 d_bz d_bP";
export var datasheetWrapperFull = "r_q1 d_cD";
export var datasheetWrapperFullLeft = "r_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "r_mv d_lv";
export var contentWrapperCenter = "r_q2 d_lz";
export var datasheetLeftWrapperCenter = "r_q3 d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "r_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "r_q4 d_lw";
export var datasheetFooter = "r_ly d_ly";
export var alignLeft = "r_qR d_dv";
export var alignCenter = "r_bP d_dw";
export var alignRight = "r_qS d_dx";
export var datasheetInnerWrapperNewLeft = "r_q5 d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "r_q6 d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "r_q7 d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "r_q8 d_bK";
export var datasheetSubtitle = "r_q9 d_cx";
export var tableRow = "r_rb";
export var cell = "r_rc";
export var tableRowWrapper = "r_rd d_w d_dv";
export var tableHeadingCell = "r_rf";
export var tableHeading = "r_rg";
export var tableRowStriped = "r_rh";
export var tableRowHeading = "r_rj";
export var dataTable = "r_rk d_w";
export var imageWrapper = "r_rl d_fg d_Z";
export var imageWrapperFull = "r_rm d_H d_w d_bf d_Z";
export var imageWrapperIcon = "r_rn";
export var titleMargin = "r_rp d_cs";
export var datasheetInnerInnerWrapper = "r_rq d_w";
export var hasLabels = "r_rr";
export var label = "r_rs";
export var SmallSmall = "r_rt C_rt C_sZ C_tl";
export var SmallNormal = "r_rv C_rv C_sZ C_tm";
export var SmallLarge = "r_rw C_rw C_sZ C_tj";