// extracted by mini-css-extract-plugin
export var alignLeft = "s_qR d_bG";
export var alignCenter = "s_bP d_bD";
export var alignRight = "s_qS d_bH";
export var textAlignLeft = "s_rx";
export var textAlignCenter = "s_ry";
export var textAlignRight = "s_rz";
export var embedInnerWrapperDesign1 = "s_rB d_bM";
export var embed = "s_rC d_b1";
export var titleMargin = "s_rp d_cw d_dw";
export var subtitleMargin = "s_rD d_cs d_dw";
export var paragraphMargin = "s_rF d_cw d_dw";
export var SubtitleSmall = "s_qd C_qd C_sZ C_s9";
export var SubtitleNormal = "s_qf C_qf C_sZ C_tb";
export var SubtitleLarge = "s_qg C_qg C_sZ C_tc";
export var BodySmall = "s_rG C_rG C_sZ C_th";
export var BodyNormal = "s_rH C_rH C_sZ C_tj";
export var BodyLarge = "s_rJ C_rJ C_sZ C_tk";